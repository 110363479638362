import React from 'react';
import { Information, Form } from './components';
import { Section } from 'components/organisms';
import { makeStyles } from '@material-ui/core/styles';
import { SectionHeader } from 'components/molecules';
import { DefaultSEO } from 'components/DefaultSEO';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => ({
  sectionAlternateForm: {
    [theme.breakpoints.up('md')]: {
      sectionAlternateForm: {
        maxWidth: '75%',
        margin: 'auto',
      },
    },
  },
  header: {
    marginBottom: -60,
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      marginBottom: -75,
    },
  },
}));

const Contact = ({ data, className, ...rest }): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <DefaultSEO
        title="THE CENTER SPACE | Connect With Us"
        description="Phone: +852 2163 7688 Email: growth@thecenterspace.co Address: Level 76, The Center, 99 Queen's Road Central, Central, Hong Kong"
      />
      <Section>
        <SectionHeader
          title={t('contactTitle')}
          subtitle={t('contactSubtitle')}
          titleVariant="h3"
          className={classes.header}
        />
      </Section>
      <Section>
        <Information />
      </Section>
      <div className={classes.sectionAlternateForm}>
        <Section>
          <Form />
        </Section>
      </div>
    </div>
  );
};

export default Contact;
