import React from 'react';
import Contact from 'views/Contact';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import { graphql } from 'gatsby';

const ContactView = (): JSX.Element => {
  return <WithLayout component={Contact} layout={Main} />;
};

export default ContactView;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
